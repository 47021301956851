import {ApolloGraphqlIcon} from '@xolvio/xolvio-ui'
import React from "react";

export const pageData = {
  header: {
    icon: <ApolloGraphqlIcon height="100px" width="100px" />,
    subheading: "SERVICES / DIGITAL EXPERIENCE INTEGRATION",
    heading: "Experience Graph Managed Services",
    description:
      "Outsource design & development of your Supergraph with the official Apollo GraphQL professional services partner."
  },
  firstSection: {
    header:
      "Distributed systems and data are fragmenting your user experiences.",
    headerHighlights:
      "fragmenting",
    listItemBold:
      "You know your user experiences shouldn’t be held hostage to your siloed systems. It is high time to enable UX standardization and evolution, so that you can delight your customers.",
    listItemRegular:
      "Today’s omnichannel reality combined with enterprise architecture complexity make cross-planning between your teams extremely difficult, with backend teams burdened by frontend demands. This bottleneck slows down your customer-facing initiatives, effectively crippling your ability to innovate on digital experience."
  },
  secondSection: {
    subheading: "EXPERIENCE GRAPH MANAGED SERVICES",
    header: "One Supergraph for all your experiences",
    headerHighlights: "---",
    content:
      "Replace your API headaches with a single, federated GraphQL API to power all of your digital experiences—the Experience Graph. Our managed services approach to designing and implementing your omnichannel experience API lifts the burden of GraphQL adoption off your teams’ shoulders. As a result, you will start leveraging unprecedented UX flexibility in the shortest time possible."
  },
  outcomeSection: {
    subheading: "OUTCOME: CX ENABLEMENT",
    header: "Deliver excellent CX with ease",
    headerHighlights: "with ease",
    content:
      "Once your custom CX Integration Platform is fully operational, your teams are going to be perfectly enabled to deliver the excellent digital experiences your customers deserve. The Platform will allow you to decouple your frontend and backend concerns, removing bottlenecks and cross-planning headaches. With state-of-the-art developer experience and unprecedented data discoverability, you can finally maximize productivity and accelerate CX innovation."
  },
  howWeDoIt: {
    subheading: "HOW WE DO IT",
    sectionHeader: "End-to-end solution ownership",
    body:
      "Through our extensive experience as the official Apollo GraphQL professional services partner we came up with a tried and tested methodology for accelerating the design and development of federated GraphQL APIs. You can count on us to bring your Experience Graph to production within weeks instead of months.",
    toutGroup1: [
      {
        title: "Scoping analysis & roadmap planning",
        body:
          "We begin the engagement with visual requirements facilitation workshops to identify the scope for the Experience Graph, get to know your architecture, and create a project roadmap.",
        footer: "Duration: 1-2 weeks"
      },
      {
        title: "Federated GraphQL API in production",
        body:
          "Armed with a deep understanding of your experiences’ demand for data, we proceed to design and build your federated GraphQL API as per the project roadmap.",
        footer: "Duration: 4-6 weeks"
      },
      {
        title: "Ongoing Experience Graph evolution",
        body:
          "We take care of bringing new experiences and data capabilities to the Graph for you. This also includes GraphQL-native migration of legacy systems and overall API governance.",
        footer: "We support your Experience Graph for the long haul."
      }
    ],
  },
  graphQL: {
    subheading: "HOW WE DO IT",
    header: "The official Apollo GraphQL partner",
    headerHighlights: "cutting-edge",
    content:
      "GraphQL is THE modern API technology. We have been at the forefront of GraphQL development since its initial release in 2015 and we use the best-in-class tooling from Apollo. Your Experience Graph is going to leverage Apollo’s Supergraph implementation, which is powering digital experiences at Fortune 500 companies such as Netflix, Walmart, and Paypal."
  },
  studyKikSection: {
    subheading: "EXPERIENCE GRAPH CASE STUDY",
    header: "Digital experience transformation",
    headerHighlights: "transformation",
    content:
      "In just three months, we refactored StudyKIK’s complex monolith into an event-driven, distributed architecture and kickstarted their Experience Graph with a Supergraph in production."
  },
  contactFooter: {
    heading: "Let’s talk about your CX Integration Platform",
    subheading: "GET IN TOUCH",
    content: "Drop us a line or schedule a 30m intro call at your convenience.",
    buttonText: "Contact us",
    buttonUrl: "/contact-us"
  }
};
