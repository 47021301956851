import {
  ButtonPrimaryLink,
  CenteredContentWrapper,
  ConfettiBigSectionWrapper,
  device,
  EmphasizedText,
  Hero,
  SectionHeader,
  spacing,
  Subheading,
  TextBody,
  TextToutFullwidth,
  ThumbnailHeader,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage,
} from "@xolvio/xolvio-ui";
import React from "react";
import styled from "styled-components";
import { pageData } from "../../pages-content/services/experience-graph-managed";
import { LayoutDefault } from "../../components/LayoutDefault";
import {
  HeroContent,
  HeroContentWrapper,
  InlineHeroWithTwoColumnsHeader,
  TwoColumnSectionHeader,
} from "../../components/Services/ServicePageTemplate";
import { ContentWrapper } from "quality-faster/src/components/LandingPage/ContentWrapper";
import { AlternateBackground, ToutRow } from "../../components/Homepage";
import { MoveHack } from "../../components/Services";
import { StyledImg } from "./rapid-backend-development";
import { contactUsFooterProps } from "../../pages-content/homepage";

const ExperienceGraphManagedServices = () => (
  <LayoutDefault>
    <div>
      <MoveHack topMobile={"-50px"} />
      <ConfettiBigSectionWrapper
        hideMobileConfetti
        style={{
          backgroundColor: "#ffffff",
          height: "220px",
          marginBottom: "30px",
          zIndex: 100,
        }}
      >
        <HeaderContentWrapper>
          {pageData.header.icon}
          <Subheader>{pageData.header.subheading}</Subheader>
          <Header style={{ maxWidth: "100% !important", textAlign: "center" }}>
            {pageData.header.heading}
          </Header>
          <HeaderBody>{pageData.header.description}</HeaderBody>
        </HeaderContentWrapper>
      </ConfettiBigSectionWrapper>

      <MoveHack top={"80px"} topMobile={"120px"} />

      <Hero
        inline
        style={{ height: "450px" }}
        heading={
          <InlineHeroWithTwoColumnsHeader>
            <EmphasizedText
              text={pageData.firstSection.header}
              highlight={pageData.firstSection.headerHighlights}
            />
          </InlineHeroWithTwoColumnsHeader>
        }
        children={
          <HeroContentWrapper>
            <div />
            <HeroContent margin="100" marginTablet="100">
              <TextBody>{pageData.firstSection.listItemRegular}</TextBody>
              <TextBody bold>{pageData.firstSection.listItemBold}</TextBody>
            </HeroContent>
          </HeroContentWrapper>
        }
      />

      <MoveHack topMobile={"120px"} topPhone={"320px"} />

      <TwoColumnsWithImage
        imagePosition={"right"}
        imageNode={
          <StyledImg src={"/assets/images/apollo_2.svg"} width="500px" />
        }
        contentNode={
          <TwoColumnContentNodeWrapper>
            <Subheading>{pageData.secondSection.subheading}</Subheading>
            <TwoColumnSectionHeader>
              <EmphasizedText
                highlight={pageData.secondSection.headerHighlights}
                text={pageData.secondSection.header}
              />
            </TwoColumnSectionHeader>
            <Text>{pageData.secondSection.content}</Text>
          </TwoColumnContentNodeWrapper>
        }
      />
      <MoveHack topMobile={"100px"} />

      <AlternateBackground>
        <CenteredContentWrapper id="howWeWork">
          <ContentWrapper>
            <Subheading style={{ textAlign: "center" }}>
              {pageData.howWeDoIt.subheading}
            </Subheading>
            <SectionHeader>{pageData.howWeDoIt.sectionHeader}</SectionHeader>
            <TextBody style={{ textAlign: "left" }}>
              {pageData.howWeDoIt.body}
            </TextBody>

            <MoveHack top={"50px"} topMobile={"80px"} />

            <ToutRow>
              {pageData.howWeDoIt.toutGroup1.map((tout) => (
                <div>
                  <ThumbnailHeader style={{ margin: "auto" }}>
                    {tout.title}
                  </ThumbnailHeader>
                  <TextBody
                    style={{ margin: "20px auto 0", textAlign: "left" }}
                  >
                    {tout.body}
                  </TextBody>
                  <TextBody
                    style={{ margin: "20px auto 0", textAlign: "left" }}
                  >
                    {tout.footer}
                  </TextBody>
                </div>
              ))}
            </ToutRow>
          </ContentWrapper>
        </CenteredContentWrapper>
      </AlternateBackground>

      <MoveHack top={"100px"} topMobile={"100px"} />

      <TwoColumnsWithImage
        imagePosition={"right"}
        imageNode={
          <StyledImg src={"/assets/images/graphql.png"} width="500px" />
        }
        contentNode={
          <TwoColumnContentNodeWrapper>
            <Subheading>{pageData.graphQL.subheading}</Subheading>
            <TwoColumnSectionHeader>
              <EmphasizedText
                highlight={pageData.graphQL.headerHighlights}
                text={pageData.graphQL.header}
              />
            </TwoColumnSectionHeader>
            <Text>{pageData.graphQL.content}</Text>
          </TwoColumnContentNodeWrapper>
        }
      />

      <MoveHack top={"-50px"} topMobile={"100px"} />

      <TwoColumnsWithImage
        imagePosition={"left"}
        imageNode={
          <StyledImg src={"/assets/images/logos/studykik.png"} width="500px" />
        }
        contentNode={
          <TwoColumnContentNodeWrapper>
            <Subheading>{pageData.studyKikSection.subheading}</Subheading>
            <TwoColumnSectionHeader>
              <EmphasizedText
                highlight={pageData.studyKikSection.headerHighlights}
                text={pageData.studyKikSection.header}
              />
            </TwoColumnSectionHeader>
            <Text>{pageData.studyKikSection.content}</Text>
            <MoveHack topMobile={"50px"} />
            <ButtonPrimaryLink
              as={"a"}
              href={"/case-studies/studykik-case-study/"}
            >
              Find out more
            </ButtonPrimaryLink>
            <MoveHack topMobile={"50px"} />
          </TwoColumnContentNodeWrapper>
        }
      />

      <MoveHack topMobile={"100px"} />

      <TextToutFullwidth {...contactUsFooterProps} />
    </div>
  </LayoutDefault>
);

export default ExperienceGraphManagedServices;

export const HeaderContentWrapper = styled.div`
  flex: 1;
  max-width: 1000px;
  text-align: center;
  justify-content: center;
  margin-bottom: 25px;

  > * {
    text-align: center;
    justify-content: center;
    display: flex;
    margin: auto;
    width: 100%;
  }
  @media ${device.tablet} {
    text-align: left;
    margin-bottom: 65px;
  }
  @media ${device.mobile} {
    margin-bottom: -80px;
  }
`;

export const Subheader = styled(Subheading)`
  margin-top: ${spacing.mobile.padding.default}px;
`;

export const Header = styled(SectionHeader)`
  max-width: calc(100% - 150px);
  @media ${device.tablet} {
    text-align: center;
    max-width: 750px;
  }
`;

export const HeaderBody = styled(TextBody)`
  max-width: 750px !important;
  text-align: center !important;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.2px;
`;

const Text = styled(TextBody)`
  margin: 16px 0 24px;
`;
